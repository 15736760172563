<template>
  <v-dialog
    v-model="show"
    max-width="400px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar
        dark
        color="#7253CF"
        :height="$vuetify.breakpoint.smAndUp ? '103' : '100px'"
      >
        <v-spacer></v-spacer>
        <v-toolbar-title class="modalTitle pl-6">
          <div>Reset your password</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="align-self-baseline" icon @click="show = false">
          <v-icon size="28">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="" class="px-16 mt-6">
        <span class="textField-label ">Enter your email address</span>
        <div class="mt-1 mb-2">
          <v-text-field
            v-model="email"
            type="email"
            :disabled="loading"
            outlined
            dense
            :rules="[rules.required, rules.email]"
          ></v-text-field>
        </div>
        <div class="mb-5 mt-n3">
          <v-btn :loading="loading" depressed @click="onClickResetLinkHandler()" width="100%" dark color="#CC0000">
            <span style="text-transform: none; letter-spacing: 0px;">
              Send Reset Link
            </span>
          </v-btn>
          <div>
          <a style="color:#38227A" href="mailto:support@harlemwizardsinabox.com">Click here to send a email to support</a>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {} from "@/constants/APIKeys";
import {} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
import { API_COMMON_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";

export default {
  name: "ResetPasswordModal",
  components: {},
  data() {
    return {
      rules: RULES,
      email: '',
      loading: false
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getResetPasswordModalShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        if (!value) this.clearModal();
        return this.toggleModal({ show: value });
      },
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleResetPasswordModal",
      showToast: "snackBar/showToast",
    }),
    clearModal() {
      this.loading = false;
    },
    onClickResetLinkHandler() {
      const _this = this;
      _this.loading = true;
      /**
       * Success handler of API call
       */
      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        _this.showToast({ message: data.message, color: 's' })
        _this.loading = false;
      };
      /**
       * Failure handler of API call
       */
      const failureHandler = (res) => {
        console.log("Failure ", res);
        _this.loading = false;
      };
      let formData = {};
      formData['user_email'] = this.email;
      formData['redirect_url'] = window.location.href

      /**
       * API Call for login
       */
      Axios.request_GET(
        API_COMMON_URL.forgotPassword,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.modalTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 315%;
  color: #FFFFFF;
}
.toggleForm-btn{
  text-transform: none;
  text-decoration-line: underline;
  color: blue;
  font-weight: 500;
  letter-spacing: normal;
  padding: 0px;
  height: 20px;
}
.textField-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.61);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 219%;
    color: #ffffff;
  }
  .textField-label {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #2d2d2d;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
